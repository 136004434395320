<!-- =========================================================================================
  File Name: .vue
  Description: eCommerce Item Detail page
========================================================================================== -->

<template>
  <div id="item-detail-page">
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
            <p>
              <b>Choose one of our consultation servies or comprehensive care plan .</b>
            </p>
          </div>
        </vx-card>
      </div>
    </div>
    <br />
    <br />
    <!-- bannerImg -->
        <!--div class="knowledge-base-jumbotron">
            <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Dedicated Source Used on Website</h1>
                <p class="text-white">Bonbon sesame snaps lemon drops marshmallow ice cream carrot cake croissant wafer.</p>
                <vs-input icon-no-border placeholder="Search Topic or Keyword" v-model="knowledgeBaseSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6" />
            </div>
        </div -->

    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in filteredKB"
        :key="item.value"
        @click="$router.push(item.url).catch(() => {})"
      >
        <vx-card class="text-center cursor-pointer">
          <img :src="item.graphic" alt="graphic" width="180" class="mx-auto mb-4" />
          <h4 class="mb-2">{{ item.label.toUpperCase() }}</h4>
          <small v-for="ptype in item.patientType" :key="ptype.value">{{ ptype }}</small>
        </vx-card>
      </div>
    </div>
    <!--pre>{{ serviceRequestsData }}</pre-->
       <br />
    <!-- div class="vx-row">
      <vx-card class="text-center cursor-pointer">
        <h4 class="mb-2">XXX</h4>
      </vx-card>
    </div -->


  
    <br />
  </div>
</template>
<script>
//import axios from '@/axios.js';

import Prism from "vue-prism-component";

export default {
  components: {
    Prism
  },
  data() {
    return {
      treatmentSearchQuery: "",
      //Broken Tooth,Emergency, Extraction,Implant Consultation, Teeth Dentistry
      bannerImg:
        "https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png",
      error_occured: false,
      error_msg: "",
      selected: null,
      privacy: false,
      patientTypeOptions: [
        { label: "Existing", value: "1" },
        { label: "New", value: "0" }
      ],
      endDate: "",
      dayNo: 147,
      packId: 5,
      renderedAvalibilityAMPMInfo: [],
      selectedDayNo: "",
      slotStartHour: "",
      slotStartMinute: "",
      packAvailability: [],
      paris: "",
      baghdad: "",
    };
  },
  computed: {
    carePlanData() {
      console.log(this.$store.state.careplan.cities);

      return this.$store.state.careplan.cities;
    },
    filteredKB() {
      //return this.$store.state.eCommerce.wishList.slice().reverse()
      //return this.appointmentTypeOptions.filter((item) => item.label.toLowerCase().includes(this.treatmentSearchQuery.toLowerCase()));
      return this.$store.state.careplan.carePlanTypeOptions.filter(item =>
        item.label
          .toLowerCase()
          .includes(this.treatmentSearchQuery.toLowerCase())
      );
    }
  },
  methods: {
    fetchAMPM() {

      return null;
    }
  },

  created() {
    
  }
};
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
  background-image: url("https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png");
  background-size: cover;
}
</style>